'use client';

import ServerError from '@/components/ServerError';
import axios from 'axios';
import { useEffect } from 'react';

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    axios.post('/api/kmmReportApi', { error: error, message: error.message || 'Lumberjack ui error happened' });
  }, [error]);

  return <ServerError message={error.message} />;
}
